import { Controller } from "@hotwired/stimulus"
import { debounce } from "lodash-es"

export default class extends Controller {
  static targets = [
    "input",
    "results",
    "value"
  ]

  static values = {
    path: String,
    searchKey: { type: String, default: "q" },
    contentType: { type: String, default: "text/x.autocomplete+html" },
    minLength: { type: Number, default: 0 },
    strictMode: { type: Boolean, default: false }
  }

  connect () {
    if (!this.pathValue) throw new Error(`${this.identifier} controller: path must be supplied`)

    // keep track of valueTarget's original data-attribute keys
    if (this.hasValueTarget) {
      this.valueTargetOriginalDataAttributes = Object.keys(this.valueTarget.dataset)
    }

    this.resultsTarget.hidden = true

    this.inputTarget.setAttribute("autocomplete", "off")
    this.inputTarget.setAttribute("spellcheck", "false")

    this.mouseInResults = false

    // add event listeners
    this.fetchResultDebounced = debounce(this.fetchResults.bind(this), 300)
    this.inputTarget.addEventListener("input", this.fetchResultDebounced)

    this.boundOpenResults = this.openResults.bind(this)
    this.inputTarget.addEventListener("focus", this.boundOpenResults)

    this.boundCloseResults = this.closeResults.bind(this)
    this.inputTarget.addEventListener("blur", this.boundCloseResults)

    this.boundPreventResultsClosure = this.preventResultsClosure.bind(this)
    this.resultsTarget.addEventListener("mousedown", this.boundPreventResultsClosure)
  }

  disconnect () {
    // remove event listeners
    this.inputTarget.removeEventListener("focus", this.boundOpenResults)
    this.inputTarget.removeEventListener("input", this.fetchResultDebounced)
    this.inputTarget.removeEventListener("blur", this.boundCloseResults)
    this.resultsTarget.removeEventListener("mousedown", this.boundOpenResults)
  }

  preventResultsClosure () {
    this.mouseInResults = true
    this.resultsTarget.addEventListener("mouseup", () => (this.mouseInResults = false), { once: true })
  }

  fetchResults () {
    if (!this.pathValue) return

    // Reset hidden field
    if (this.hasValueTarget) {
      this.valueTarget.value = ""
      this.valueTarget.dispatchEvent(new Event('change'))
      this.clearDataAttributes(this.valueTarget, this.valueTargetOriginalDataAttributes)
    }

    const query = this.inputTarget.value.trim()

    if (!query || query.length < this.minLengthValue) return

    this.dispatch("loadStart")

    fetch(this.url(query).toString(), this.headers())
      .then(response => response.text())
      .then(html => {
        this.resultsTarget.innerHTML = html
        this.dispatch("loadSuccess")
        this.openResults()
      })
      .catch(() => {
        this.dispatch("loadError")
      })
      .finally(() => {
        this.dispatch("loadEnd")
      })
  }

  openResults () {
    if (!this.resultsTarget.hidden) return
    this.resultsTarget.hidden = false
    this.element.setAttribute("aria-expanded", "true")
  }

  closeResults () {
    if (this.mouseInResults) return
    if (this.resultsTarget.hidden) return

    this.resultsTarget.hidden = true
    this.inputTarget.removeAttribute("aria-activedescendant")
    this.element.setAttribute("aria-expanded", "false")

    // Reset input field if hidden field is empty (i.e no option selected)
    if (this.strictModeValue && this.hasValueTarget && !this.valueTarget.value) {
      this.inputTarget.value = ""
      this.inputTarget.dispatchEvent(new Event('change'))
      this.dispatch("inputCleared", { detail: { input: this.inputTarget } })
    }
  }

  selectOption (event) {
    event.preventDefault()

    this.inputTarget.value = event.currentTarget.dataset.text || event.currentTarget.textContent
    this.valueTarget.value = event.currentTarget.dataset.value
    // Delete current data-attributes from valueTarget
    this.clearDataAttributes(this.valueTarget, this.valueTargetOriginalDataAttributes)
    // copy the selected option's data-attributes, except data-action, data-text and data-value
    this.copyDataAttributes(event.currentTarget, this.valueTarget, ['action', 'text', 'value'])
    this.valueTarget.dispatchEvent(new Event('change'))

    this.closeResults()

    this.dispatch("optionSelected", { detail: { option: event.currentTarget } })
  }

  url (query) {
    const url = new URL(this.pathValue, window.location.href)
    const params = new URLSearchParams(url.search.slice(1))
    params.append(this.searchKeyValue, query)
    url.search = params.toString()
    return url
  }

  headers () {
    return {
      "X-Requested-With": "XMLHttpRequest",
      Accept: this.contentTypeValue
    }
  }

  clearDataAttributes (element, attributesToIgnore = []) {
    Object.entries(element.dataset).forEach(([key, _]) => {
      if (!attributesToIgnore.includes(key)) {
        delete this.valueTarget.dataset[key]
      }
    })
  }

  copyDataAttributes (fromElement, toElement, attributesToIgnore = []) {
    Object.entries(fromElement.dataset).forEach(([key, value]) => {
      if (!attributesToIgnore.includes(key)) {
       toElement.dataset[key] = value
      }
    })
  }
}
